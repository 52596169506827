import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import classnames from "classnames";
import uuid from "react-uuid";
import { productItems, apiItems, adminItems } from "./items";
import { AuthorizerStore } from "../stores";
import SidebarItem from "./SidebarItem";
import styles from "./styles/sidebar.module.sass";
import useScrollEndDetection from "../hooks/useScrollEndDetection";

/**
 * Sidebar colapsível que expande ao passar o mouse
 * @component
 * @returns {JSX.Element} Rendered Sidebar component
 */
const Sidebar = memo(() => {
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [elementRef, atEnd] = useScrollEndDetection();

  const handleLogout = () => {
    AuthorizerStore.setToken("");
    history.push("/login");
  };

  return (
    <nav
      className={classnames([
        styles.sidebar,
        "navbar-dark bg-dark d-none d-md-flex",
        { [styles.continue]: !atEnd, [styles.expanded]: isOpen },
      ])}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <ul ref={elementRef} className="navbar-nav">
        {[...productItems, ...apiItems, ...adminItems].map((item) => (
          <SidebarItem
            key={`item_${uuid()}`}
            isSidebarOpen={isOpen}
            {...item}
          />
        ))}

        <SidebarItem
          isSidebarOpen={isOpen}
          title="Logout"
          icon={MdLogout}
          onClick={handleLogout}
        />
      </ul>
    </nav>
  );
});

export default Sidebar;
