import React, { memo, useEffect, useState } from "react";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown } from "reactstrap";
import { Link } from "react-router-dom";
import { Else, If, Then, When } from "react-if";
import uuid from "react-uuid";
import { isAllow } from "../../../utils";

const Empty = () => <></>;
const HeaderItem = memo(
    ({
      title,
      target,
      notification,
      onClick = () => {},
      roles = [],
      icon: Icon = Empty,
      subItems = [],
    }) => {
      const [notifications, setNotifications] = useState(0);
      useEffect(() => {
        const id = notification?.run(setNotifications);
        return () => !!id && clearInterval(id);
      }, []);
      return (
        <When condition={isAllow(roles)}>
          <If condition={!subItems.length}>
            <Then>
              <NavItem className="pl-2 d-md-none">
                <NavLink tag={Link} to={target} onClick={onClick}>
                  <Icon size={25} />
                  <span>&nbsp;{title}&nbsp;</span>
                  <When condition={notifications}>
                    <Badge color="danger">{notifications}</Badge>
                  </When>
                </NavLink>
              </NavItem>
            </Then>
            <Else>
              <UncontrolledDropdown nav inNavbar className="pl-2 d-md-none">
                <DropdownToggle nav caret>
                  <Icon size={25} />
                  <span>&nbsp;{title}</span>
                </DropdownToggle>
                <DropdownMenu right className="bg-dark" style={{ border: 0 }}>
                  {subItems.map((item) => {
                    const SubIcon = item.icon;
                    return (
                      <When
                        key={`item-${uuid()}`}
                        condition={isAllow(item.roles)}
                      >
                        <DropdownItem
                          className="bg-dark"
                          style={{
                            cursor: "pointer",
                            color: "rgba(255, 255, 255, 0.5)",
                          }}
                          tag={Link}
                          to={item.target}
                          onClick={onClick}
                        >
                          <SubIcon size={25} />
                          <span className="d-inline nav-link">
                            &nbsp;{item.title}
                          </span>
                        </DropdownItem>
                      </When>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Else>
          </If>
        </When>
      );
    }
  );

  export default HeaderItem;