import { useCallback } from 'react';

/**
 * Hook personalizado para gerenciar notificações com intervalo
 * @param {Function} func - Função assíncrona que retorna o número de notificações
 * @param {number} interval - Intervalo em milissegundos entre as verificações (-1 para desabilitar)
 * @returns {Function} Função de configuração que aceita um callback para atualizar o estado
 */
export const useNotification = (func = async () => 0, interval = -1) => {
  const setupNotification = useCallback((callback) => {
   // Função que executa a verificação e atualiza o estado através do callback
    const executor = async () => {
      const result = await func();
      callback(result);
    };
    
    // Agenda a primeira execução após o intervalo inicial
    const initialTimeout = setTimeout(executor, interval);
    
    // Se o intervalo for válido (> -1), configura a execução periódica
    const intervalId = interval > -1 ? 
      setInterval(executor, interval) : 
      null;
        
    // Retorna uma função de limpeza que será chamada quando o componente for desmontado
    // ou quando as dependências mudarem
    return () => {
      clearTimeout(initialTimeout);
      
      if (intervalId) clearInterval(intervalId);
    };
  }, [func, interval]);

  return setupNotification;
};
