import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useInterval } from "./useInterval";
import VersionUpdateMessage from "components/VersionUpdateMessage";
import { useFetch, useLocalStorage } from "hooks";

const toastConfig = {
  closeButton: false,
  pauseOnHover: false,
  closeOnClick: true,
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 7000,
};

const headers = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'Pragma': 'no-cache',
  'Expires': '0'
}

function useEmptyCacheStorage() {
  const [, setCurrentVersion] = useLocalStorage('APP_VERSION', '');
  const history = useHistory();
  return async function(newVersion) {
    if('caches' in window){
      const keys = await caches.keys();
      await Promise.all(keys.map(caches.delete));
    }
    setCurrentVersion(newVersion);
    history.go(0);
  }
}

export const useVersionCheck = () => {
  const [currentVersion, setCurrentVersion] = useLocalStorage('APP_VERSION', '');
  const [fetchVersion, { version: newVersion }] = useFetch(() => axios.get(`${window.location.origin}/meta.json`, { headers }), currentVersion);
  const emptyCacheStorage = useEmptyCacheStorage();
  const onClose = useCallback(() => emptyCacheStorage(newVersion), [newVersion]);
  if(currentVersion) {
    if (!!newVersion && currentVersion !== newVersion) {
      const config = { ...toastConfig, onClose }
      try {
        toast.info(<VersionUpdateMessage />, config);
      } catch (error) {
        toast.error("Falha ao limpar cache. Por favor recarregue a página.");
      }
    }
  } else if(newVersion) {
    setImmediate(() => setCurrentVersion(newVersion));
  }
  useInterval(fetchVersion, 60*1000);
  useEffect(() => { fetchVersion(); }, []);
};
