import { useState, useEffect, useRef, useCallback } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useHistory, useLocation } from "react-router-dom";
import useSoundInner from 'use-sound';
import ndjson from 'fetch-ndjson';
import qs from "qs";
import { UtilsStore } from "../stores";
import { companyApi } from "../api";

export function useNavegationReset(paths, resetFn){
  const history = useHistory();
  useEffect(() => {
      const unblock = history.block(({ pathname }) => {
        if(paths.every(path => !pathname.startsWith(path))){
          resetFn();
        }
      });
      return () => unblock();
    }, []);
}

export function useTimeout(callback, delay) {
  const callbackRef = useRef(callback)
  const timeoutRef = useRef()
  useEffect(() => {
      callbackRef.current = callback
  }, [callback])
  const set = useCallback(() => {
      timeoutRef.current = setTimeout(() => callbackRef.current(), delay)
  }, [delay])
  const clear = useCallback(() => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
  }, [])
  useEffect(() => {
      set()
      return clear
  }, [delay, set, clear])
  const reset = useCallback(() => {
      clear()
      set()
  }, [clear, set])
  return { reset, clear }
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const saved = localStorage.getItem(key);
    if (saved) {
      const initial = JSON.parse(saved);
      return initial;
    }
    return defaultValue
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const saved = sessionStorage.getItem(key);
    if (saved) {
      const initial = JSON.parse(saved);
      return initial;
    }
    return defaultValue
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const useSound = value => {
  const [old, setOld] = useState(0);
  const [enabled, setEnabled] = useLocalStorage('sound-notification', true);
  const [play] = useSoundInner('https://storagecbdprd.blob.core.windows.net/cbdpublic/mixkit-clear-announce-tones-2861.wav');
  useEffect(() => {
      if (enabled && !!value && value > old){
          play()
          setOld(value);
      }
  }, [value, enabled])
  return [!enabled, () => setEnabled(!enabled)]
}

export function useFetchState(initialValue){
  const [response, setResponse] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  return [{ response, loading, error }, { setResponse, setLoading, setError }]
}

export const useFetch = (request, initialValue) => {
  const [{ response, loading, error }, { setResponse, setLoading, setError }] = useFetchState(initialValue);
  const fetch = async (...args) => {
    setError(false);
    try {
      setLoading(true);
      const res = await request(...args);
      setResponse(res);
    } catch (err) {
      setError(true);
      throw err;
    } finally {
      setLoading(false);
    }
  };
  return [fetch, response, loading, error];
};

export function useFetchND(request, transform=item => item, isLocal=false) {
  const [{ response, loading, error }, { setResponse, setLoading, setError }] = useFetchState([]);
  const fetch = async () => {
    setError(false);
    try {
      if(!isLocal) {
        UtilsStore.setLoading(true);
      }
      setLoading(true);
      const { body } = await request();
      const reader = body.getReader();
      for await (const item of ndjson(reader)) {
        setResponse(res => [...res, transform(item)]);
        if(!isLocal) {
          UtilsStore.setLoading(false);
        }
      }
    } catch (err) {
      setError(true);
      UtilsStore.setLoading(false);
      throw err;
    } finally {
      setLoading(false);
    }
  };
  return [fetch, response, loading, error];
}

const cachedScripts = new Map();

export function useScript(src, name) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    // If cachedScripts array already includes src that means another instance ...
    // ... of this hook already loaded this script, so no need to load again.
    if (cachedScripts.get(name)) {
      setState({
        loaded: true,
        error: false,
      });
    } else {
      cachedScripts.set(name, src);

      // Create script
      let script = document.createElement('script');
      script.src = src;
      script.async = false;

      // Script event listener callbacks for load and error
      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false,
        });
      };

      const onScriptError = () => {
        // Remove from cachedScripts we can try loading again
        const exist = cachedScripts.get(name);
        if (exist) {
          cachedScripts.delete(name);
        }
        script.remove();

        setState({
          loaded: true,
          error: true,
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      // Add script to document body
      document.body.appendChild(script);

      // Remove event listeners on cleanup
      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [name, src]); // Only re-run effect if script src and name changes

  return [state.loaded, state.error];
}

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState('ontouchstart' in document.documentElement);
  useEffect(() => {
    setIsMobile('ontouchstart' in document.documentElement);
  }, [window.innerWidth]);
  return isMobile;
};

export const useMediaQuery = query => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

export const useDimensions = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const resize = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth)
  }
  useEventListener('resize', resize);
  return { width, height };
}

export const useSSE = (url, onMessage = console.log, onOpen) => {
  useEffect(() => {
    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
      },
    });
    eventSource.addEventListener("message", onMessage);
    eventSource.addEventListener("error", console.warn);
    eventSource.addEventListener("open", onOpen);
    return () => {
      eventSource.close()
    }
  }, [url]);
}

export const useOnScreen = (ref) => {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    observer.observe(ref.current)
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}

export function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);
      // Add event listener
      element.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

export function useCompanies() {
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    (async () => {
      const content = await companyApi.getAllStream();
      const companiesOptions = content.map(({ uuid, name }) => {
          return { value: uuid, label: name };
      });
      setCompanies(companiesOptions);
    })();
  }, []);
  return companies;
}

export function useSearchParams() {
  const { search } = useLocation();
  return qs.parse(search, { ignoreQueryPrefix: true });
}