import React from "react";
import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import styles from "./styles.module.sass";

/**
 * TabSelector component for handling tab navigation
 * @component
 * @param {Object} props - Component props
 * @param {string|number} props.activeTab - Currently active tab identifier
 * @param {string|number} props.tabIndex - Index/identifier for this tab
 * @param {Function} props.onClick - Callback function when tab is clicked
 * @param {string} [props.className] - Additional CSS class names
 * @param {boolean} [props.error] - Whether to show error state
 * @param {React.ReactNode} props.children - Tab content
 * @returns {React.ReactElement} TabSelector component
 */
const TabSelector = ({
  activeTab,
  tabIndex,
  onClick,
  className,
  children,
  error,
  ...props
}) => {
  const isActive = activeTab === tabIndex;

  return (
    <NavItem className={styles.tab}>
      <NavLink
        data-testid="tab-link"
        {...props}
        className={classnames([
          "d-flex align-items-center justify-content-center w-100",
          className,
          {
            active: isActive,
            [styles.error]: error,
            [styles.active]: isActive,
          },
        ])}
        onClick={() => onClick(tabIndex)}
      >
        {children}
      </NavLink>
    </NavItem>
  );
};

export default TabSelector;
