import React, { useCallback } from "react";
import IntlCurrencyInput from "react-intl-currency-input";
import { Col, FormGroup, Label, Row } from "reactstrap";
import classnames from "classnames";
import { When } from "react-if";
import { useFeedbackModalContext } from "./Context";
import { hypothesisValues } from "./model";
import Loader, { Resolve } from "components/loader";
import { getCurrentUser } from "utils";
import { vehiclePartApi } from "api";
import { Select } from "components";

const style = { cursor: 'pointer' };

const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
};

async function load(){
    const { Company } = getCurrentUser();
    const vehicleParts = await vehiclePartApi.getAllVehicleParts(Company);
    return vehicleParts.map(({ name, id }) => ({ label: name, value: id }));
}

function InnerForm({ hasError, vehicleParts }) {
    const [{ value, feedbackInspectDTO: { others, coveredDamages, flaggedAnalyst, helpful, vehiclePartsDTO }}, { setValue, setHelpful, onChangeDamagedHypothesis, setVehicleParts, setFlaggedAnalyst }] = useFeedbackModalContext();
    const onChangeHelpful = useCallback(value => setHelpful(value), []);
    const onChangeFlaggedAnalyst = useCallback(value => setFlaggedAnalyst(value), []);
    const onChangeValue = useCallback( (_, value) => setValue(value), []);

    return (
        <>
            <FormGroup row>
                <Label md="3" className="font-weight-bold">Hipótese</Label>
                {
                    hypothesisValues().map(({ value, label, validate }) => (
                        <Col xs="4" className={classnames([{ 'text-danger': hasError && (others === null || coveredDamages === null) }])} key={value} style={style} onClick={() => onChangeDamagedHypothesis(value)}>
                            <input type="radio" name="hypothesis" className={classnames(['radioCheckbox', { 'border border-danger':  hasError && (others === null || coveredDamages === null) }])} checked={validate(coveredDamages, others)} />
                            &nbsp;{label}
                        </Col>
                    ))
                }
            </FormGroup>
            <When condition={coveredDamages}>
                <FormGroup row>
                    <Label md="3" className="font-weight-bold">A consulta ajudou na tomada de decisão?</Label>
                    <Col md="3" className={classnames([{ 'text-danger': hasError && helpful === null }, 'd-flex align-items-center'])} style={style} onClick={() => onChangeHelpful(true)}>
                        <input type="radio" name="helpful" value="true" className={classnames(['radioCheckbox', { 'border border-danger':  hasError && helpful === null }])} checked={helpful}/>
                        &nbsp;Sim
                    </Col>
                    <Col md="3" className={classnames([{ 'text-danger': hasError && helpful === null }, 'd-flex align-items-center'])}  style={style} onClick={() => onChangeHelpful(false)}>
                        <input type="radio" name="helpful" value="false" className={classnames(['radioCheckbox', { 'border border-danger':  hasError && helpful === null }])} checked={helpful === false}/>
                        &nbsp;Não
                    </Col>
                </FormGroup>
                <When condition={helpful}>
                    <FormGroup>
                        <Label className="font-weight-bold">Qual valor você está deixando em DNC?*</Label>
                        <Row className="mb-1">
                            <Col>
                                <IntlCurrencyInput currency="BRL" config={currencyConfig} onChange={onChangeValue} max={9999999.99} value={value} className={classnames(['form-control', { 'border border-danger':  hasError && !value }])}/>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Label for="vehicleParts" className="font-weight-bold">Peças Relacionadas*</Label>
                        <Row>
                            <Col>
                                <Select id="vehicleParts" style={{ zIndex: 50 }} options={vehicleParts} value={vehicleParts.filter(({ value }) => vehiclePartsDTO?.some(({ id }) => id === value))} onChange={values => setVehicleParts(values.map(({ value, label }) => ({ id: value, name: label })))} isSearchable isMulti placeholder="Peças"/>
                            </Col>
                        </Row>
                    </FormGroup>
                </When>
            </When>
            <When condition={others}>
                <FormGroup row>
                    <Label md="3" className="font-weight-bold">Sinalizou o Analista?</Label>
                    <Col md="3" className={classnames([{ 'text-danger': hasError && flaggedAnalyst === null }, 'd-flex align-items-center'])} style={style} onClick={() => onChangeFlaggedAnalyst(true)}>
                        <input type="radio" name="flaggedAnalyst" value="true" className={classnames(['radioCheckbox', { 'border border-danger':  hasError && flaggedAnalyst === null}])} checked={flaggedAnalyst}/>
                        &nbsp;Sim
                    </Col>
                    <Col md="3" className={classnames([{ 'text-danger': hasError && flaggedAnalyst === null }, 'd-flex align-items-center'])}  style={style} onClick={() => onChangeFlaggedAnalyst(false)}>
                        <input type="radio" name="flaggedAnalyst" value="false" className={classnames(['radioCheckbox', { 'border border-danger':  hasError && flaggedAnalyst === null}])} checked={flaggedAnalyst === false}/>
                        &nbsp;Não
                    </Col>
                </FormGroup>
            </When>
        </>
    );
}

export default function ({ hasError }) {
    return (
        <Loader promiseFn={load}>
            <Resolve>
                { vehicleParts => <InnerForm hasError={hasError} vehicleParts={vehicleParts} />}
            </Resolve>
        </Loader>
    )
}