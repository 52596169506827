import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const Back = ({ to, onClick = () => {} }) => {
  return (
    <Button tag={Link} to={to} onClick={onClick}>
      <FaArrowLeft />
      &nbsp;Voltar
    </Button>
  );
};
export default Back;
