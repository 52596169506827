import React, { memo } from "react";
import { Else, If, Then } from "react-if";
import classnames from "classnames";
import Header from "./components/Header";
import Sidebar from "./Sidebar";
import { AuthorizerStore } from "stores";
import styles from "./styles/body.module.sass";

export default memo(({ children }) => {
  const { authenticated } = AuthorizerStore;
  return (
    <div style={{ height: "100vh", width: "100vw" }} className={classnames([{ 'bg-dark': !authenticated }])}>
      <If condition={authenticated}>
        <Then>
          <Header />
          <Sidebar />
        </Then>
        <Else>
          <div className={styles.version}>
            {process.env.VERSION}
          </div>
        </Else>
      </If>
      <main>{children}</main>
    </div>
  );
});
