import React from "react";

const VersionUpdateMessage = () => (
  <div className="d-flex flex-column">
    <strong className="text-info mb-3 h5">Novidades chegando!!</strong>

    <div className="d-flex flex-column">
      <p className="text-muted m-0">
        Seu sistema está sendo atualizado para a nossa versão mais recente.
      </p>
      <p className="text-muted mt-2">
        Não se preocupe, a atualização será feita automaticamente.
      </p>
    </div>
  </div>
);

export default VersionUpdateMessage;
