import { useState, useEffect, useRef } from 'react';

/**
 * Hook para detectar quando um elemento scrollável chegou ao final
 * @returns {[React.RefObject, boolean]} Returns a tuple with the element ref and whether it's at the end
 */
export const useScrollEndDetection = () => {
  const [atEnd, setAtEnd] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      const element = elementRef.current;

      if (!element) return;

      // Cache DOM measurements to avoid reflow
      const {
        scrollTop,
        clientHeight,
        scrollHeight
      } = element;

      const isScrollable = scrollHeight > clientHeight;
      // Verifica se o elemento está no final
      const atBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight + 5;

      setAtEnd(!isScrollable || atBottom);
    }

    const element = elementRef.current;

    if (element) {
      // Configura o estado inicial
      const isScrollable = element.scrollHeight > element.clientHeight;
      setAtEnd(!isScrollable);
      
      element.addEventListener('scroll', handleScroll);
    }

    // Cleanup
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return [elementRef, atEnd];
};

export default useScrollEndDetection; 