import React from "react";
import { Label, FormGroup } from "reactstrap";
import { default as RcSwitch } from "rc-switch";
import classnames from "classnames";
import PropTypes from "prop-types";
import { When } from "react-if";
import "rc-switch/assets/index.css";
import "./styles.sass";

/**
 * A customizable switch/toggle component that wraps rc-switch with additional styling and layout options.
 *
 * @component
 * @example
 * ```jsx
 * <Switch
 *   id="my-switch"
 *   label="Enable Feature"
 *   onChange={(checked) => console.log('Switch toggled:', checked)}
 *   checked={true}
 * />
 * ```
 *
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the switch
 * @param {string|React.ReactNode} props.label - Label text or node to display with the switch
 * @param {Function} props.onChange - Callback function triggered when switch value changes
 * @param {boolean} [props.floatRight=false] - Float the switch container to the right
 * @param {boolean} [props.floatLeft=false] - Float the switch container to the left
 * @param {boolean} [props.revert=false] - Reverse the order of label and switch
 * @param {boolean} [props.bold=false] - Apply bold styling to the label
 * @param {boolean} [props.checked] - Controlled checked state of the switch
 * @param {boolean} [props.defaultChecked] - Initial checked state for uncontrolled component
 * @param {boolean} [props.disabled] - Disable the switch
 */
const Switch = ({
  id,
  label,
  onChange,
  floatRight,
  floatLeft,
  revert,
  bold,
  checked,
  ...rest
}) => (
  <FormGroup
    data-testid="switch-form-group"
    className={classnames([
      "d-flex align-items-center",
      {
        "float-right": floatRight,
        "float-left": floatLeft,
      },
    ])}
  >
    <When condition={!revert}>
      <Label
        for={id}
        className={classnames({ "font-weight-bold": bold })}
        style={{ fontSize: "14px" }}
      >
        {label}
      </Label>
      &nbsp;
    </When>
    <RcSwitch id={id} onChange={onChange} checked={checked} {...rest} />
    <When condition={revert}>
      &nbsp;
      <Label
        for={id}
        className={classnames({ "font-weight-bold": bold })}
        style={{ fontSize: "14px" }}
      >
        {label}
      </Label>
    </When>
  </FormGroup>
);

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onChange: PropTypes.func.isRequired,
  floatLeft: PropTypes.bool,
  floatRight: PropTypes.bool,
};

Switch.defaultProps = {
  floatLeft: false,
  floatRight: false,
};

export default Switch;
