import React, { Suspense } from "react";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { Router, Route, Switch } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";
import { ToastContainer } from "react-toastify";
import { Provider, observer } from "mobx-react";
import { PulseLoader } from 'react-spinners';
import { withRouter } from "react-router";
import { Container } from "reactstrap";
import { CaptureStore, CameraStore, AuthorizerStore, ReportStore, TargetListStore, UtilsStore, QueryParamsStore } from "./stores";
import { RoutingStore, history, AuthenticateRoute } from "./routing";
import { useVersionCheck } from "./hooks/useVersionCheck";
import { CheckVersion, Downloader } from "./components";
import { privateRoutes, publicRoutes } from './routes';
import Layout from './layout';
import "react-toastify/dist/ReactToastify.css";
import "./App.sass";

const Fallback = () => (
  <div className="h-100 d-flex">
    <div className="m-auto">
      <PulseLoader />
    </div>
  </div>
)

function getStyles(isLoading) {
  return {
    wrapper: (base) => ({
      ...base,  
      overflow: isLoading ? "" : "auto !important",
      height: AuthorizerStore.authenticated
        ? "calc(100vh - 60px)"
        : "100vh",
    }),
    overlay: (base) => ({
      ...base, zIndex: "2000 !important"
    })
  }
};

function Routes() {
  useVersionCheck();
  const style = {
    minHeight: AuthorizerStore.authenticated ? "" : "100vh",
    display: !AuthorizerStore.authenticated ? "flex" : "",
    maxWidth: "99.6%",
  };
  return (
    <Suspense fallback={<Fallback />}>
        <Container fluid style={style}>
          <Switch>
            {privateRoutes.map(
              ({ component, roles, ...rest }, index) => (
                <Route
                  key={`private-${index}`}
                  {...rest}
                  render={(props) => (
                    <AuthenticateRoute
                      {...{ ...props, component, roles }}
                    />
                  )}
                />
              )
            )}
            {publicRoutes.map((route, index) => (
              <Route key={`public-${index}`} {...route} />
            ))}
          </Switch>
          <br />
          <Downloader />
        </Container>
      </Suspense>
  );
}

const App = withRouter(
  observer(() => {
    const isLoading = !!(UtilsStore.loading || AuthorizerStore.loading);

    return (
      <Layout>
        <LoadingOverlay active={isLoading} fadeSpeed={200} spinner styles={getStyles(isLoading)}>
          <ToastContainer limit="1" />
          <CheckVersion>
            <Routes/>
          </CheckVersion>
        </LoadingOverlay>
      </Layout>
    );
  })
);

// const App = withRouter(
//   observer(() => {
//     const isLoading = !!(UtilsStore.loading || AuthorizerStore.loading);

//     return (
//       <Layout>
//         <LoadingOverlay
//           active={isLoading}
//           fadeSpeed={200}
//           spinner
//           styles={{
//             wrapper: (base) => ({
//               ...base,
//               overflow: isLoading ? "" : "auto !important",
//               height: AuthorizerStore.authenticated
//                 ? "calc(100vh - 60px)"
//                 : "100vh",
//             }),
//             overlay: (base) => ({
//               ...base,
//               zIndex: "2000 !important",
//             }),
//           }}
//         >
//           <ToastContainer limit="1" />

//           <ClearCache>
//             {({ emptyCacheStorage }) => {
//               useVersionCheck(emptyCacheStorage);

//               return (
//                 <CheckVersion>
//                   {/* {AuthorizerStore.authenticated && <Conpass />} */}
//                   <Suspense fallback={<Fallback />}>
//                     <Container
//                       fluid
//                       style={}
//                     >
//                       <Switch>
//                         {privateRoutes.map(
//                           ({ component, roles, ...rest }, index) => (
//                             <Route
//                               key={`private-${index}`}
//                               {...rest}
//                               render={(props) => (
//                                 <AuthenticateRoute
//                                   {...{ ...props, component, roles }}
//                                 />
//                               )}
//                             />
//                           )
//                         )}
//                         {publicRoutes.map((route, index) => (
//                           <Route key={`public-${index}`} {...route} />
//                         ))}
//                       </Switch>
//                       <br />
//                       <Downloader />
//                     </Container>
//                   </Suspense>
//                 </CheckVersion>
//               );
//             }}
//           </ClearCache>
//         </LoadingOverlay>
//       </Layout>
//     );
//   })
// );

const stores = {
  AuthorizerStore,
  CaptureStore,
  RoutingStore,
  CameraStore,
  ReportStore,
  TargetListStore,
  UtilsStore,
  QueryParamsStore,
};

export default () => (
  <Router history={history}>
    <Provider {...stores}>
      <AnimatePresence>
        <App />
      </AnimatePresence>
    </Provider>
  </Router>
);
