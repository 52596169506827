import { FaBuilding, FaBullseye, FaCamera, FaChartPie, FaCheck, FaCheckSquare, FaEye, FaFileAlt, FaIdCard, FaPhone, FaSatelliteDish, FaSearch, FaSearchengin, FaTh, FaTimes, FaTruckLoading, FaUpload, FaUserCheck, FaUsers, FaVoteYea, FaWifi } from "react-icons/fa";
import { PiWebhooksLogoDuotone } from "react-icons/pi"
import qs from "qs";
import { Roles } from "../model";
import { isAllow } from "../utils";
import Api from "../api";
import { ONE_HOUR_IN_MS } from "../utils/consts";

const getEnrollment = async () => {
    if(isAllow([Roles.ROLE_ADMIN])) {
        const api = new Api(process.env.REACT_APP_ENROLLMENT_ENDPOINT);
        const filter = qs.stringify(
            { status: ['PENDING', 'PULL_REQUEST'] },
            { addQueryPrefix: false, arrayFormat: 'comma' }
        );
        const { totalElements } = await api.fetch(filter);
        return totalElements;
    }
    return 0;
}

class Item {
    constructor(title, icon, target='', roles=[], subItems=[], notificationConfig=null){
        this.title = title;
        this.target = target;
        this.icon = icon;
        this.hasSubItems = !!subItems.length;
        this.subItems = subItems;
        this.roles = roles;
        this.notificationConfig = notificationConfig;
    }
}

export const productItems = [
    new Item('Dashboard', FaChartPie, '/dashboard', [Roles.ROLE_ADMIN, Roles.ROLE_INSURANCE_MANAGER]),
    new Item('Pesquisar', FaSearch, '/search', [ Roles.ROLE_ADMIN, Roles.ROLE_ANALYST, Roles.ROLE_SEARCH, Roles.ROLE_INSURANCE_ANALYST, Roles.ROLE_RESEARCHER_CAMERA, Roles.ROLE_INSURANCE_MANAGER, Roles.ROLE_HOTLIST_MANAGER, Roles.ROLE_INSPECTOR]),
    new Item('Tracking', FaSatelliteDish, '/tracking', [Roles.ROLE_ADMIN, Roles.ROLE_INSURANCE_CARTRACKING]),
    new Item('Clientes', FaUsers, undefined, [Roles.ROLE_ADMIN, Roles.ROLE_CUSTOMER_MANAGER], [
        new Item('Busca', FaSearch, '/clientes/busca'),
        new Item('Uploads', FaUpload, '/clientes/uploads'),
    ]),
    new Item('Avaliações', FaCheckSquare, undefined, [
        Roles.ROLE_ADMIN,
        Roles.ROLE_INSURANCE_ANALYST,
        Roles.ROLE_INSURANCE_MANAGER,
        Roles.ROLE_HOTLIST_MANAGER,
        Roles.ROLE_INSPECTOR
    ], [
        new Item('Realizadas', FaCheck, '/avaliacoes'),
        new Item('Uploads', FaUpload, '/avaliacoes/uploads', [Roles.ROLE_ADMIN]),
        new Item('Pendentes', FaTimes, '/avaliacoes/pendentes')
    ]),
    new Item('Target', FaBullseye, '/targetList', [Roles.ROLE_ANALYST]),
    new Item('Target', FaBullseye, '/qualidade', [Roles.ROLE_QUALITY]),
    new Item('Target', FaBullseye, undefined, [Roles.ROLE_ADMIN],[
        new Item('Busca', FaSearch, '/targetList'),
        new Item('Qualidade', FaVoteYea, '/qualidade'),
    ]),
    new Item('Uploader', FaUpload, '/uploader/qualidade', [Roles.ROLE_QUALITY]),
    new Item('Uploader', FaUpload, undefined, [Roles.ROLE_ADMIN], [
        new Item('Uploads', FaUpload, '/uploader/uploads'),
        new Item('Qualidade', FaVoteYea, '/uploader/qualidade'),
    ]),
    new Item('Consulta Integrada', FaSearchengin, '/integrada', [
        Roles.ROLE_ADMIN,
        Roles.ROLE_HOTLIST_MANAGER,
        Roles.ROLE_HOTLIST_VIEWER
    ]),
    new Item('Veículo Dublê', FaIdCard, undefined, [Roles.ROLE_ADMIN], [
        new Item('Validação de suspeitas', FaCheck, '/duble/suspeita'),
        new Item('Qualidade', FaVoteYea, '/duble/qualidade'),
    ]),
    new Item('Monitoramento', FaEye, '/tracker/check', [Roles.ROLE_HOTLIST_VIEWER]),
    new Item('Monitoramento', FaVoteYea, '/tracker/qualidade', [Roles.ROLE_QUALITY_HOTLIST, Roles.ROLE_QUALITY]),
    new Item('Monitoramento', FaEye, undefined, [Roles.ROLE_ADMIN, Roles.ROLE_HOTLIST_MANAGER], [
        new Item('Buscar', FaSearch, '/tracker'),
        new Item('Qualidade', FaVoteYea, '/tracker/qualidade', [Roles.ROLE_ADMIN]),
        new Item('Contatos', FaPhone, '/tracker/contatos'),
        new Item('Webhooks', PiWebhooksLogoDuotone, '/tracker/webhooks', [Roles.ROLE_ADMIN])
    ]),
    new Item('Dossiês', FaFileAlt, '/dossie', [Roles.ROLE_ADMIN, Roles.ROLE_ANALYST]),
];

export const apiItems = [
    new Item('Quali-API', FaVoteYea, '/quali-api/qualidade', [Roles.ROLE_QUALITY]),
    new Item('Quali-API', FaWifi, undefined, [Roles.ROLE_ADMIN], [
        new Item('Uploads', FaUpload, '/quali-api/uploads'),
        new Item('Qualidade', FaVoteYea, '/quali-api/qualidade'),
    ])
];

export const adminItems = [
    new Item('Câmeras', FaCamera, undefined, [Roles.ROLE_ADMIN, Roles.ROLE_CAMERA_ADMIN], [
        new Item('Busca', FaSearch, '/cameras'),
        new Item('Monitoramento', FaTh,'/cameras/monitor'),
    ]),
    new Item('Fornecedores', FaTruckLoading, '/fornecedores', [Roles.ROLE_ADMIN, Roles.ROLE_CAMERA_ADMIN]),
    new Item('Empresas', FaBuilding, '/empresas', [Roles.ROLE_ADMIN]),
    new Item('Usuários', FaUsers, undefined, [Roles.ROLE_ADMIN], [
        new Item('Busca', FaSearch, '/usuarios'),
        new Item('Uploads', FaUpload, '/usuarios/uploads'),
    ]),
    new Item('Solicitações de acesso', FaUserCheck, '/solicitacoes', [Roles.ROLE_ADMIN], [], {
        func: getEnrollment,
        interval: ONE_HOUR_IN_MS
      }),
];
