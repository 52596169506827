function enumValue(value, label, validate ) {
    return Object.freeze({ value: Object.freeze(value), label, validate });
};

const YES_RELEASED = enumValue(
    {
        avoidedInquiry: true,
        releasedClaim: true,
        refusedClaim: false
    },
    'Sim, com Sinistro Liberado',
    (avoidedInquiry, releasedClaim, refusedClaim) => avoidedInquiry && releasedClaim && refusedClaim === false
);
const YES_REFUSED = enumValue(
    {
        avoidedInquiry: true,
        releasedClaim: false,
        refusedClaim: true
    },
    'Sim, com Sinistro Recusado sem Sindicância',
    (avoidedInquiry, releasedClaim, refusedClaim) => avoidedInquiry && releasedClaim === false && refusedClaim
);
const NO = enumValue(
    {
        avoidedInquiry: false,
        releasedClaim: false,
        refusedClaim: false
    },
    'Não',
    (avoidedInquiry, releasedClaim, refusedClaim) => avoidedInquiry === false && releasedClaim === false && refusedClaim === false)
;

export function inquiryValues() {
    return [YES_RELEASED, YES_REFUSED, NO];
}

const COVERED_DAMAGE = enumValue(
    {
        coveredDamages: true,
        others: false
    },
    "Danos Não Cobertos",
    (coveredDamages, others) => coveredDamages && !others
);

const OTHERS = enumValue(
    {
        coveredDamages: false,
        others: true
    },
    "Outros",
    (coveredDamages, others) => !coveredDamages && others
);

export function hypothesisValues(){
    return [COVERED_DAMAGE, OTHERS];
}

