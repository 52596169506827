import React, { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { When } from "react-if";
import uuid from "react-uuid";
import { isAllow } from "../utils";
import NotificationBadge from "./NotificationBadge";
import styles from "./styles/sidebar.module.sass";
import { useNotification } from "../hooks/useNotification";

const Empty = () => <></>;

// Função auxiliar para checar se há subitems ativos
const hasSubItemActive = (subItems, pathname) =>
  subItems.some(({ target, subItems }) =>
    !!subItems?.length
      ? hasSubItemActive(subItems, pathname)
      : pathname.startsWith(target)
  );

/**
 * Item de navegação da Sidebar
 * @typedef {Object} Props
 * @property {string} title - Título do item
 * @property {string} [target] - Caminho do item
 * @property {boolean} isSidebarOpen - Se o sidebar está aberto
 * @property {Object} [notificationConfig] - Objeto de configuração de notificação
 * @property {Function} [onClick] - Função de clique
 * @property {Array<string>} [roles] - Roles necessárias para acessar o item
 * @property {Function} [icon] - Componente de ícone
 * @property {Array<Object>} [subItems] - Items aninhados ao item principal
 */

/**
 * Item de navegação da Sidebar
 * @component
 * @param {Props} props - Propriedades do componente
 * @returns {JSX.Element} Componente de item de navegação da Sidebar
 */
const SidebarItem = memo(
  ({
    title,
    target,
    isSidebarOpen,
    notificationConfig,
    onClick = () => {},
    roles = [],
    icon: Icon = Empty,
    subItems = [],
  }) => {
    const { pathname } = useLocation();
    const [isOpen, setOpen] = useState(false);
    const [notifications, setNotifications] = useState(0);

    // expande o submenu ou executa a função onClick
    const handleClick = (event) => {
      if (!!subItems.length) {
        event.preventDefault();
        setOpen((prev) => !prev);
      } else {
        onClick(event);
      }
    };

    // Handle sidebar open/close state
    useEffect(() => {
      if (isSidebarOpen) {
        setOpen(
          !!subItems?.length
            ? hasSubItemActive(subItems, pathname)
            : pathname.startsWith(target)
        );
      } else {
        setOpen(false);
      }
    }, [isSidebarOpen, subItems, pathname, target]);

    /* Se existir uma config de notificação, chama o hook useNotification com a função  e o intervalo definito na
       criação do item e retorna uma função para executar a notificação
    */
    const setupNotification = notificationConfig
      ? useNotification(notificationConfig.func, notificationConfig.interval)
      : null;

    useEffect(() => {
      // Se existir uma função de notificação, executa a função passando o setNotifications como callback e atualiza o número de notificações
      if (setupNotification) {
        return setupNotification(setNotifications);
      }
    }, [setupNotification]);

    const active = !!subItems?.length
      ? hasSubItemActive(subItems, pathname)
      : pathname.startsWith(target);

    return (
      <When condition={isAllow(roles)}>
        <NavItem
          active={active}
          className={classnames([
            { [styles.header]: !!subItems?.length, [styles.open]: isOpen },
            "pl-2",
          ])}
        >
          <NavLink tag={Link} to={target} onClick={handleClick}>
            <Icon size={20} />
            <NotificationBadge count={notifications} />
            <span className={classnames(["ml-3", { [styles.open]: isOpen }])}>
              {title}
            </span>
            <NotificationBadge count={notifications} />
          </NavLink>

          <When condition={subItems.length && isOpen}>
            <Nav
              vertical
              navbar
              className={classnames([styles.submenu, "ml-2"])}
              data-testid="list-subitems"
            >
              {subItems.map((item) => (
                <SidebarItem
                  key={`item_${uuid()}`}
                  isSidebarOpen={isSidebarOpen}
                  {...item}
                />
              ))}
            </Nav>
          </When>
        </NavItem>
      </When>
    );
  }
);

export default SidebarItem;
