import React from "react";
import classnames from "classnames";
import { formatPlateNewPatten, formatPlateToOldPattern } from "utils";
import styles from "./style.module.sass";

/**
 * Props for the Plate component
 * @typedef {Object} PlateProps
 * @property {string} value - The license plate number to display
 * @property {string} [className] - Optional CSS class name to apply to the plate element
 * @property {boolean} [old=false] - Whether to display the plate in old format
 */

/**
 * Component to display vehicle license plates in either new Mercosul or old format
 * @param {PlateProps} props - The component props
 * @returns {JSX.Element}
 */
export default function Plate({ value, className = "", old = false }) {
  const formatPlate = old ? formatPlateToOldPattern : formatPlateNewPatten;

  return (
    <span
      data-testid="license-plate"
      className={classnames(className, {
        [styles.old]: old,
        [styles.plate]: !old,
      })}
    >
      {formatPlate(value)}
    </span>
  );
}
